<template>
  <div>
    <index-filter :cols="[3,3]">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
      <status-filter
        slot="col_1"
        v-model="filters"
        placeholder="Search Review"
        :only="['published', 'rejected', 'pending', 'unpublished']"
      />
    </index-filter>

    <v-card>
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="paginate"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>

          <v-chip
            v-if="filters.product_id"
            class="ml-4"
            color="primary"
            outlined
          >
            Product ID: {{ filters.product_id }}
            <v-btn
              icon
              x-small
              color="primary"
              @click="reserFilterField('product_id')"
            >
              <v-icon size="15">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-chip>
        </div>

        <!-- <div>
          <create-dialog
            v-if="$can('product_review:create', 'product_review_management')"
            :distributor-id="distributorId"
            @created="paginate"
          />
        </div> -->
      </v-card-text>

      <product-review-table
        ref="tableRef"
        :table-list="tableList"
      />
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { IndexFilter, SearchFilter, StatusFilter } from '@/components'
import { useTableList } from '@/composables'
import router from '@/router'
import { mdiClose, mdiRefresh } from '@mdi/js'
import ProductReviewTable from '../components/productReview/ProductReviewTable.vue'
import { productReviewService } from '../services'

export default {
  components: {
    IndexFilter,
    SearchFilter,
    StatusFilter,

    // TeamIdFilter,
    ProductReviewTable,
  },
  setup(props) {
    const filters = ref({
      search: null,
      status: null,
      product_id: router.currentRoute.query.product_id || undefined,
    })

    const tableList = useTableList(productReviewService, filters)

    const resetRoute = () => {
      router.replace({ query: { ...filters.value } })
      tableList.paginate()
    }

    const reserFilterField = field => {
      filters.value[field] = undefined
      resetRoute()
    }

    const {
      loading,
      tableRef,
      paginate,
    } = tableList

    return {
      reserFilterField,
      filters,

      loading,
      tableRef,
      paginate,
      tableList,

      icons: {
        mdiRefresh,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
